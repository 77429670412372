@import 'src/variables.scss';

.section {
  padding-left: 120px;
  padding-right: 120px;
  display: flex;
  flex-direction: column;
  width: 100%;

  background: url('../../assets/images/background.png') no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @include breakpoint(sm) {
    padding-left: 30px;
    padding-right: 30px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 83px;
    padding-bottom: 69px;
    gap: 47px;
    @include breakpoint(md, sm) {
      flex-direction: column;
    }

    @include breakpoint(sm) {
        padding-top: 33px;
        padding-bottom: 33px;
        gap: 50px;
      }

    .block {
      margin-top: 63px;
      display: flex;
      flex-direction: column;
      flex: 1;
      text-align: left;
      @include breakpoint(md, sm) {
        margin-top: 0;
      }
      .title {
        margin: 0;
        color: $xlineWhite;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 58px;
        text-transform: uppercase;
        @include breakpoint(sm) {
            font-size: 32px;
          }
      }
      .blue {
        color: $xlineBlue;
      }
      .subtitle {
        padding-top: 29px;
        padding-bottom: 64px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        opacity: 0.8;
        margin: 0;
        color: $xlineWhite;

        @include breakpoint(sm) {
            font-size: 16px;
          }
      }
    }
  }
}
