@import 'src/variables.scss';

.button {
  background: $xlineBlue;
  width: 340px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  cursor: pointer;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: $xlineWhite;

  @include breakpoint(sm) {
    width: 100%;
  }
}

.modal {
  all: unset;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
}

.content {
  width: 700px;
  height: 446px;
  background: $xlineWhite;
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 117px;
  padding-right: 117px;
  flex-direction: column;

  @include breakpoint(sm) {
    width: 100%;
    height: 100%;
    padding-left: 47px;
  padding-right: 47px;
  }

  .closeButton {
    all: unset;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .logo {
    margin-bottom: 48px;
    width: 85px;
    height: 38px;
  }
  .head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    margin: 0;
    color: $xlineDark;
    margin-bottom: 19px;
  }
  .blue {
    color: $xlineBlue !important;
  }
  .body {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    margin-bottom: 40px;
    color: $xlineDarkGray;
  }
  .inputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 9px;

    @include breakpoint(sm) {
        flex-direction: column;
        align-items: flex-start;
      }
    div {
      display: flex;
      flex-direction: column;
      gap: 6px;
      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        margin: 0;
      }
      input {
        width: 285px;
        height: 35px;

        background: #ffffff;
        border: 1px solid rgba(170, 173, 175, 0.5);
      }
    }
    button {
      all: unset;
      width: 172px;
      max-height: 35px;
      padding: 10px 24px;
      cursor: pointer;

      background: $xlineBlue;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: $xlineWhite;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
