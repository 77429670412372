@import 'src/variables.scss';

.section {
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding-top: 82px;
  padding-bottom: 78px;
  padding-left: 120px;
  padding-right: 120px;

  @include breakpoint(sm) {
    padding-left: 30px;
  padding-right: 30px;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    color: $xlineBlue;
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 52px;
    flex-wrap: wrap;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      min-width: 250px;
      .head {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: $xlineDark;
        margin: 0;
        margin-bottom: 12px;
      }
      .body {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        /* gray medium */

        color: $xlineDarkGray;
      }
      .icon{
        width: 79px;
        height: 79px;
        margin-bottom: 32px;
      }
    }
  }
}
