@import 'src/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  .button {
    all: unset;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-transform: uppercase;
    color: $xlineDarkGray;
    cursor: pointer;
  }
  .selected {
    text-decoration-line: underline;
    color: $xlineBlue;
  }
}
