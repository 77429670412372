@import 'src/variables.scss';

.footer {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  background: $xlineDark;
  gap: 60px;

  @include breakpoint(sm) {
    padding-left: 30px;
    padding-right: 30px;
    gap: 30px;
  }

  .actionRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include breakpoint(md, sm) {
      flex-direction: column;
      gap: 20px;
    }
    .text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 44px;
      text-transform: uppercase;
      color: $xlineWhite;
      margin: 0;
    }
    .blue {
      color: $xlineBlue !important;
    }
  }
  .bottomNavgigation {
    height: 113px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border-top: 1px solid rgba(204, 205, 206, 0.5);

    svg {
      @include breakpoint(sm) {
        width: 60px;
      }
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 89px;
      @include breakpoint(md, sm) {
        gap: 19px;
      }
      @include breakpoint(sm) {
        gap: 9px;
      }
    }
  }
}
