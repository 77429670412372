@import 'src/variables.scss';

.section {
  padding-top: 56px;
  padding-bottom: 70px;
  padding-left: 120px;
  padding-right: 120px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: $xlineDark;

  @include breakpoint(sm) {
    padding-left: 30px;
  padding-right: 30px;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    color: $xlineBlue;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;

    .itemHovered {
      background: $xlineBlue !important;
      p {
        color: $xlineWhite !important;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      gap: 20px;
      flex: 1;
      background: #ffffff;
      min-width: 250px;

      .head {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        margin: 0;
        color: $xlineDark;
      }
      .body {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        color: $xlineDarkGray;
      }
    }
  }
}
