@import 'src/variables.scss';

.header {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-bottom: 1px solid rgba(204, 205, 206, 0.5);

  svg {
    @include breakpoint(sm) {
      width: 60px;
    }
  }
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 89px;
    @include breakpoint(md, sm) {
      gap: 19px;
    }
    @include breakpoint(sm) {
      gap: 9px;
    }
  }
}
